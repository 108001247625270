.rejectVolunteer {
  border: 1px solid #fc2626;
  border-radius: 50%;
  color: #fc2626;
}
.rejectVolunteer.active {
  border: 1px solid #fc2626;
  border-radius: 50%;
  background-color: #fc2626;
  color: white;
}

.acceptVolunteer {
  border: 1px solid #03ba03;
  border-radius: 50%;
  color: #03ba03;
}

.acceptVolunteer.active {
  border: 1px solid #03ba03;
  border-radius: 50%;
  background-color: #03ba03;
  color: white;
}
