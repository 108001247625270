.unsetVolunteer {
  border: 1px solid #3880ff;
  border-radius: 50%;
  color: #3880ff;
}

.unsetVolunteer.active {
  border: 1px solid #3880ff;
  border-radius: 50%;
  background-color: #3880ff;
  color: white;
}

.shift-detail.loading {
  opacity: 0.5;
}

div.select-icon {
  display: none !important;
}

ion-select::part(icon) {
  display: none;
}
