.login-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.login-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-footer {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.background-img {
  position: absolute;
  top: 20px;
  right: 0;
  width: 50px;
  height: auto;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-title-container {
  font-size: 40px;
  font-weight: bold;
}

.subtitle-container {
  font-size: 35px;
  font-weight: lighter;
}

.subtitle-container span {
  color: #ff00a0;
  font-weight: bold;
}

.poweredBy {
  font-size: 13px;
  font-weight: normal;
}

.login-audience-logo {
  width: 70%;
  margin: 5px auto 0;
}

.login-button {
  --background: #ff00a0;
}
